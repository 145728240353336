import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {Auth as awsAuth, Hub} from 'aws-amplify';
import {
  UPDATE_AUTH_USER,
  SET_AUTH_TOKEN,
  VENDOR_API_DATA,
  AUTH_HOSPITAL,
  AUTH_CLIENT_FLOOR,
  AUTH_CLIENT_ROOM,
} from '../../shared/constants/ActionTypes';
import {auth as firebaseAuth} from '../services/auth/firebase/firebase';
import {
  fetchStart,
  fetchSuccess,
  onGetLoggedInCognitoUser,
} from '../../redux/actions';
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
import jwtAxios from '../services/auth/jwt-auth/jwt-api';

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector(({auth}) => auth);
  // const mobileNbr = useSelector(
  //   ({auth}) => auth.mobileno,
  // );
  // const {paswd} = useSelector(({auth}) => paswd);
  // const {token} = useSelector(({auth}) => token);
  useEffect(() => {
    const awsAuthUser = () =>
      new Promise((resolve) => {
        awsAuth
          .currentAuthenticatedUser()
          .then((user) => {
            resolve();
            if (user) {
              dispatch({
                type: UPDATE_AUTH_USER,
                payload: {
                  media: user.media,
                  authType: AuthType.JWT_AUTH,
                  displayName: user.userId,
                  credentialid: user.credentialId,
                  clientid: user.clientId,
                  clientName: user.clientName,
                  billing: user.billing,
                  token: user.signInUserSession.accessToken.jwtToken,
                  roleId: user.roleId,
                  role: user.role,
                },
              });
            }
          })
          .catch(function (error) {
            resolve();
          });
        return Promise.resolve();
      });

    const firebaseCheck = () =>
      new Promise((resolve) => {
        firebaseAuth.onAuthStateChanged((authUser) => {
          if (authUser) {
            dispatch({
              type: UPDATE_AUTH_USER,
              payload: {
                authType: AuthType.FIREBASE,
                media: authUser.media,
                displayName: authUser.userId,
                billing: authUser.billing,
                credentialid: authUser.credentialId,
                clientName: authUser.clientName,
                clientid: authUser.clientId,
                token: authUser.refreshToken,
                roleId: authUser.roleId,
                role: authUser.role,
              },
            });
          }
          resolve();
        });
        return Promise.resolve();
      });

    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      // const mobileNbr = localStorage.getItem('mobileNbr');
      // const paswd = localStorage.getItem('paswd');
      // const token='';
      // const body = {"mobileNbr":mobileNbr, "paswd":paswd};
      //   if(mobileNbr){
      //   try {
      //     await jwtAxios
      //       .post('v1/patMgmt/loginjwt', body)
      //       .then((res) => {
      //         if (res.data.hasOwnProperty.call(res.data, 'message')) {
      //         } else {
      //           // localStorage.setItem('token', res.data.body.token);
      //           // localStorage.setItem('mobileNbr', mobileNbr);
      //           // localStorage.setItem('paswd', paswd);
      //           // token = localStorage.getItem('token');
      //           dispatch(setJWTToken(res.data.body.token));
      //           var body ={"mobileNbr":mobileNbr, "paswd":paswd,"jwtToken":res.data.body.token}
      //           try {
      //               jwtAxios
      //               .post('v1/patMgmt/login', body)
      //               .then((res) => {
      //                 dispatch(fetchSuccess());
      //                 var response = res.data.body[0];
      //                 localStorage.setItem('roleId', '1');
      //                 dispatch({
      //                   type: UPDATE_AUTH_USER,
      //                   payload: {
      //                     authType: AuthType.JWT_AUTH,
      //                     displayName: response.patname,
      //                     email: response.email,
      //                     mobileNo: response.mobileNo,
      //                     doctorCode: response.doctorCode,
      //                     roleId:'1',
      //                     role: 'Patient',
      //                     token: token,
      //                   },
      //                 });
      //               })
      //               .catch((error) => {
      //                 // alert(error.response.data.message.split(':')[1].trim());
      //                 dispatch({type: FETCH_ERROR, payload: (error.response.data.message.split(':')[1].trim())});
      //               });
      //             }
      //              catch (err) {
      //               dispatch(fetchSuccess());
      //               return;
      //             }
      //         }
      //       })
      //       .catch((error) => {
      //         dispatch({
      //           type: FETCH_ERROR,
      //           payload: error.response.data.message.split(':')[1].trim(),
      //         });
      //       });
      //       return;
      //   } catch (err) {
      //     return;
      //   }
      // }
      var body1 = {userId: 'test ', userPaswd: '44', jwtToken: token};
      try {
        await jwtAxios
          .post('v1/vendor/login', body1)
          .then((res) => {
            dispatch(fetchSuccess());
            var response = res.data;
            if (res.status === 200) {
              var response = res?.data?.body?.userDet[0];
              dispatch({
                type: UPDATE_AUTH_USER,
                payload: {
                  authType: AuthType.JWT_AUTH,
                  media: response.media,
                  billing: response.billing,
                  displayName: response.userId,
                  credentialid: response.credentialId,
                  clientName: response.clientName,
                  clientid: response.clientId,
                  token: '',
                  roleId: response.userCategoryId,
                  role:
                    response.userCategoryId == 1 || response.userCategoryId == 2
                      ? 'Vendor'
                      : response.categoryName,
                  credentialId: response.credentialId,
                  userCategory: response.categoryName,
                  doctorID: response.doctorID,
                  vaccineReminder: response.vaccineReminder || false,
                },
              });
              dispatch({
                type: AUTH_HOSPITAL,
                payload: res?.data?.body?.hospitalDet,
              });
              dispatch({
                type: AUTH_CLIENT_FLOOR,
                payload: res?.data?.body?.floorDet,
              });
              dispatch({
                type: AUTH_CLIENT_ROOM,
                payload: res?.data?.body?.roomDet,
              });
            }
          })
          .catch((error) => {
            // alert(error.response.data.message.split(':')[1].trim());
            // dispatch({type: FETCH_ERROR, payload: (error.response.data.message.split(':')[1].trim())});
          });

        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };

    const setJWTToken = (token) => {
      return async (dispatch) => {
        dispatch({
          type: SET_AUTH_TOKEN,
          payload: token,
        });
      };
    };

    const checkAuth = () => {
      Promise.all([firebaseCheck(), awsAuthUser(), validateAuth()]).then(() => {
        setLoading(false);
      });
      Hub.listen('auth', ({payload: {event, data}}) => {
        switch (event) {
          case 'signIn':
            dispatch(onGetLoggedInCognitoUser());
            break;
          case 'signOut':
            dispatch({type: UPDATE_AUTH_USER, payload: null});
            break;
          default:
            return false;
        }
      });
    };
    checkAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = () => {
  const {user} = useSelector(({auth}) => auth);

  if (user) {
    return {id: 1, ...user};
  }
  return [null];
};
